import React, { Component } from "react";
import Header from "../../components/contact_header/Header";
import Footer from "../../components/footer_contact/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import BlogsImg from "./BlogsImg";
import AddressImg from "./AddressImg";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";

const ContactData = contactPageData.contactSection;
const blogSection = contactPageData.blogSection;
const addressSection = contactPageData.addressSection;
const phoneSection = contactPageData.phoneSection;

class Contact extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="contact-main">
        <Header theme={theme} />
        
        
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="60px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-div">
                <img
                  src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}
                  alt=""
                />
              </div>
              <div className="contact-heading-text-div">
                  <div className="greeting-image-div">
                  {<img
  							alt=""
  							src={require("../../assests/images/qr-code.png")}
  						></img>}
  						<SocialMedia theme={theme} /> 
                  </div>  	
                 <div className="resume-btn-div">
                   <Button
                     text="See My Resume"
                     newTab={true}
                     href={greeting.resumeLink}
                     theme={theme}
                   />
                 </div>

              </div>
            </div>
            
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
          </Fade>
        </div>
      </div>
    );
  }
}

export default Contact;
