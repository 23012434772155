/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Hazem's Portfolio",
  description:
    "AI research scientist with an 8+ years track record in the fields of eXplainable AI (XAI), autonomous driving, safety analysis, and embedded systems, with a strong focus on safety-critical applications, in model-level and system-level contexts.",
  og: {
    title: "Hazem Fahmy Portfolio",
    type: "website",
    url: "https://scholar.google.com/citations?user=SMq_99wAAAAJ&hl=en",
  },
};

//Home Page
const greeting = {
  title: "Hazem Fahmy",
  logo_name: "Hazem Fahmy",
  nickname: "",
  subTitle:
    "AI research scientist with an 8+ years track record in the fields of eXplainable AI (XAI), autonomous driving, safety analysis, and embedded systems, with a strong focus on safety-critical applications, in model-level and system-level contexts.",
  resumeLink:
    "https://drive.google.com/file/d/1GvVfTuLg0kMX7fH3bD2NJnjvBK_7RRV9/view?usp=sharing",
  portfolio_repository: "https://github.com/hazemfahmyy/hazemfahmyy.github.io",
  githubProfile: "https://github.com/hazemfahmyy",
  googleScholar: "https://scholar.google.com.eg/citations?user=SMq_99wAAAAJ&hl=en",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/hazemfahmyy",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/hazem-fahmy/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Hackerrank",
    link: "https://www.hackerrank.com/hazem_fahmyy92",
    fontAwesomeIcon: "fa-hackerrank", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Kaggle",
    link: "https://www.kaggle.com/hazemfahmy",
    fontAwesomeIcon: "fa-kaggle", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#20BEFF", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Gmail",
    link: "mailto:hazem.fahmyy92@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "ORCiD",
    link: "https://orcid.org/0000-0003-1656-0291",
    fontAwesomeIcon: "fa-orcid", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#A6CE39", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "AI & Data Science",// R, SQL
      fileName: "DataScienceImg",
      skills: [
        "• Exploiting and manipulating VGG-19, SqueezeNet, HourGlass-104, NVIDIA's DAVE-2, KPNet, and CenterNet NN architectures.",
        "• Building open-source solutions to debug, repair and explain ML models & DNNs using large synthetic and real-world datasets.",
        "• Utilizing white-box/black-box strategies, features extraction methods, dimensionality reduction techniques, and unsupervised learning algorithms.",
        "• Creating information visualization, relations and insights into large complex datasets and simulation processes using R, Scikit and SQL.",
        "• Deployed on nine systems performing ADAS classification, regression, and segmentation tasks (3D object detection, driver drowsiness detection, and steering angle estimation).",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Jupyter",
          fontAwesomeClassname: "logos-jupyter",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
        {
          skillName: "Scikit-Learn",
          imageSrc: "scikit.png",
        },
        {
          skillName: "AWS",
          imageSrc: "aws.png",
        },
        {
          skillName: "R",
          imageSrc: "r.svg",
        },
        {
          skillName: "MySQL",
          imageSrc: "mysql.svg",
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "logos-azure",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Functional Testing & Scientific Computations",
      fileName: "FullStackImg",//MATLAB, Mathematica, HPC, Linux, UnrealEngine, Blender,
      skills: [
        "• Utilizing different SOO/MOO and search-based genetic algorithms (MPC, NSGA, MOSA).",
        "• Manipulating and developing synthetic simulators implemented based on UnrealEngine, Blender, and Unity3D such as Microsoft AirSim, CARLA, and BeamNG.",
        "• Experience with using Linux-based HPCs for exploring/exploiting multi-dimensional search spaces.",
      ],
      softwareSkills: [
        {
          skillName: "MATLAB",
          imageSrc: "matlab.svg",
        },
        {
          skillName: "Mathematica",
          imageSrc: "mathematica.svg",
        },
        {
          skillName: "Linux",
          imageSrc: "linux-light.svg",
        },
        {
          skillName: "UnrealEngine",
          imageSrc: "unrealengine.svg",
        },
        {
          skillName: "Blender",
          imageSrc: "blender.svg",
        },
      ],
    },
    {
      title: "SW/HW Development",
      fileName: "CloudInfraImg",//C/C++, PIC, Cadence, Xilinx, 
      skills: [ //change pic //GitLab - Visual Studio - PyCharm - Eclipse - Java - Spyder - Docker
        "• End-to-end software development encompassing the creation of mini multiplayer games using Java and Docker. Employing advanced OOP techniques, the backend architecture is crafted to ensure optimum performance, scalability, and maintainability.",
        "• Notable experience with end-to-end HW development cycle from prototyping with microcontrollers to designing electronic PCBs and deploying on FPGAs.",
        "• Developing intelligent home automation systems and sustainable energy products.",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "C",
          imageSrc: "c.svg",
        },
        {
          skillName: "C++",
          imageSrc: "cplusplus.svg",
        },
        {
          skillName: "Java",
          imageSrc: "java-wordmark.svg",
        },
        {
          skillName: "Eclipse",
          imageSrc: "eclipse.svg",
        },
        {
          skillName: "Visual Studio",
          imageSrc: "visual-studio.svg",
        },
        {
          skillName: "PyCharm",
          imageSrc: "pycharm.svg",
        },
        {
          skillName: "Docker",
          imageSrc: "docker-wordmark.svg",
        },
        {
          skillName: "Xilinx",
          imageSrc: "xilinx.svg",
        },
        {
          skillName: "Microchip",
          imageSrc: "microchip.svg",
        },
      ],
    },
    {
      title: "Contribution to Scientific Community",
      fileName: "DesignImg", //IEEE, ACM, Latex, arxiv, overleaf
      skills: [
        "• Published in ACM Transactions on Software Engineering & Methodology (TOSEM), IEEE Transactions on Vehicular Technology (TVT), Transactions on Reliability (TR).",
        "• Presented at International Conference on Automated Software Engineering (ASE'23) International Conference on Software Engineering (ICSE'22),  International Symposium on Software Reliability Engineering (ISSRE'22), International Conference on Microelectronics (ICM'17), Midwest Symposium on Circuits and Systems (MWSCAS'18).",
        "• 12 articles on XAI, ADAS, Embedded Systems, Optimization Algorithms and Mathematical Modelling, ~100 citations, h-6.",
      ],
      softwareSkills: [
        {
          skillName: "IEEE",
          imageSrc: "ieee.svg",
        },
        {
          skillName: "ACM",
          imageSrc: "acm.svg",
        },
        {
          skillName: "LateX",
          imageSrc: "latex.svg",
        },
        {
          skillName: "Overleaf",
          imageSrc: "overleaf.svg",
        },
        {
          skillName: "ArXiv",
          imageSrc: "arxiv.svg",
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "IBM: Deep Learning Fundamentals with Keras",
      iconifyClassname: "simple-icons:ibm",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://courses.edx.org/certificates/897d5b99243e4f369e47938cf58aa42e",
    },
      {
        siteName: "IBM: Applied Deep Learning Capstone Project",
        iconifyClassname: "simple-icons:ibm",
        style: {
          color: "#20BEFF",
        },
        profileLink: "https://courses.edx.org/certificates/7d948f1b014b49118130b4e9ec0d4db7",
      },
      {
        siteName: "IBM: Deep Learning Professional Program",
        iconifyClassname: "simple-icons:ibm",
        style: {
          color: "#20BEFF",
        },
        profileLink: "https://www.hackerrank.com/hazem_fahmyy92",
      },
      {
        siteName: "Coursera: Ethics, Technology and Engineering",
        iconifyClassname: "simple-icons:coursera",
        style: {
          color: "#20BEFF",
        },
        profileLink: "https://www.coursera.org/account/accomplishments/verify/7X6GFLEM9CBV",
      },
    {
      siteName: "HackerRank: Profile",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/hazem_fahmyy92",
    },
    {
      siteName: "Kaggle: Profile",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/hazemfahmy",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "PhD. in Computer Science",
      subtitle: "Université du Luxembourg",
      logo_path: "unilu.png",
      alt_name: "University of Luxembourg",
      duration: "2019 - 2023",
      descriptions: [
        "• Thesis: Automated Debugging and Repair of AI-based Automotive Software Systems.",
        "• The research is done in collaboration with IEE (www.iee.lu), an automotive company that develops sensing solutions (e.g., driver's behavior detectors) that are integrated into top-tier cars (e.g., Audi, BMW).",
        "• The research is also funded by FUNTASY/BRIDGES Luxembourg National Research Fund (FNR).",
      ],
      website_link: "",
    },
    {
      title: "MSc. in Electronics Engineering",
      subtitle: "German University in Cairo",
      logo_path: "guc.png",
      alt_name: "",
      duration: "2014 - 2015",
      descriptions: [
        "• Thesis: Safe Driving Advisor and Evaluator.",
        "• Discussing Risk Assessment, Sensor Fusion, Motion-Planning, Decision-Making, Optimization Algorithms in Advanced Driving Assistance Systems (ADAS).",
        "• Research of autonomous driving modeling using accurate and complex mathematical models to simulate and estimate a safe-state of the vehicle in different modeled road geometry scenarios.",
      ],
      website_link: "",
    },
    {
      title: "BSc. in Information Engineering & Technology",
      subtitle: "German University in Cairo",
      logo_path: "guc.png",
      alt_name: "",
      duration: "2009 - 2014",
      descriptions: [
        "• Thesis: CSP-based Self-Powered Quad-Directional Solar Water Heater.",
        "• Studies include Telecommunication & Information Technology, Microelectronics & Optical Devices, Embedded Systems, Power Electronics and Renewable Energy.",
      ],
      website_link: "",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Self-driving Car Engineer Nanodegree Program",
      subtitle: "- Udacity",
      logo_path: "Udacity_logo.png",
      certificate_link:
        "https://graduation.udacity.com/confirm/4QXK7NZ6",
      alt_name: "",
    },
    {
      title: "Deep Learning Professional Program",
      subtitle: "- IBM",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://credentials.edx.org/credentials/29d8c48517e647d4adb2bc10ff45d6d1/",
      alt_name: "IBM",
    },
    {
      title: "Deep Learning using multi-GPUs",
      subtitle: "- Google DL Workshop",
      logo_path: "nvidia.png",
      alt_name: "Google",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      alt_name: "Microsoft",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "AI Research Scientist",
          company: "Security-Reliability & Trust Centre",
          company_url: "https://www.linkedin.com/school/snt-lu/about/",
          logo_path: "snt.jpg",
          duration: "May 2023 - Present",
          location: "Kirchberg, Luxembourg",
          description: "• Led the design, development and testing process (employing MLOps, Scrum methodologies) of Python-based solutions for functional safety analysis (ISO/PAS-21448, 26262) within the Software Verification & Validation group. Focused on debugging safety-critical AI systems (regression, classification tasks) and providing readable explanations.",
          color: "#0879bf",
        },
        {
          title: "R&D Specialist",
          company: "Security-Reliability & Trust Centre - IEE Sensing",
          company_url: "https://www.linkedin.com/school/snt-lu/about/",
          logo_path: "snt.jpg",
          duration: "May 2019 - May 2023",
          location: "Belval, Luxembourg",
          description:
            "• Formulated and built open-source solutions in Python using TensorFlow, Keras, and Scikit utilizing white-box, black-box, and search-based techniques based on genetic algorithms, and ML algorithms showing an improvement up to 34% in DNNs' accuracy.",
          color: "#9b1578",
        },
        {
          title: "Research Associate",
          company: "Simula Metropolitan (SimulaMet) Center for Digital Engineering",
          company_url:"https://www.linkedin.com/company/simula-metropolitan/about/",
          logo_path: "simula.jpg",
          duration: "Jan 2019 - May 2019",
          location: "Oslo, Norway",
          description:
            "• Spearheaded the application process for research proposals, in collaboration with a dedicated research team, to secure grants and funding within the Horizon 2020 European framework.",
          color: "#fc1f20",
        },
        {
          title: "Research & Teaching Associate",
          company: "Zewail City of Science & Technology",
          company_url: "https://www.linkedin.com/school/zewailcityst/about/",
          logo_path: "zewail.jpg",
          duration: "Feb 2017 - Dec 2018",
          location: "Giza, Egypt",
          description:
            "• Directed the development of ADAS algorithms using V2V communication and sensor fusion, implemented on FPGA and PreScan and presented at international conferences (see publications).",
          color: "#fc1f20",
        },
        {
          title: "Systems Engineer",
          company: "Misr Italia Properties",
          company_url: "https://www.linkedin.com/company/misritaliaproperties/about/",
          logo_path: "mi.jpg",
          duration: "Feb 2016 - Jan 2017",
          location: "Cairo, Egypt",
          description:
            "• Architected renewable energy products using micro embedded systems in C utilizing PIC micro-controllers, BLEs, and ZigBees, with a focus on optimizing efficiency, PCB size and power.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Visiting Researcher",
          company: "Technische Universität Darmstadt",
          company_url: "https://www.linkedin.com/school/technische-universitat-darmstadt/about/",
          logo_path: "tud.jpg",
          duration: "Sep 2015 - Feb 2016",
          location: "Darmstadt, Germany",
          description: "Conducted extensive experiments on Linux-based HPC as part of a joint research work between TUD and GUC for the finalization of own masters work.",
          color: "#000000",
        },
        {
          title: "Postgraduate Researcher",
          company: "German University in Cairo",
          company_url: "https://www.linkedin.com/school/german-university-in-cairo/about/",
          logo_path: "guc.png",
          duration: "Jun 2015 - Sep 2015",
          location: "Cairo, Egypt",
          description:"Designed and developed an autonomous driving MPC-based simulator for lane-keeping and object avoidance using Mathematica and MATLAB and evaluated it in urban and highway scenarios.",
          color: "#ee3c26",
        },
        {
          title: "Trainee",
          company: "Delta Elevators",
          logo_path: "delta.jpg",
          duration: "Jun 2013 - Sep 2013",
          location: "Cairo, Egypt",
          description:"Engaged in the development of an innovative prototype for the microprocessor elevator control board, Delta Tronix Duplex board, aimed at enhancing elevator control functionality and efficiency.",
          color: "#ee3c26",
        },
        {
          title: "Research Intern",
          company: "German University in Cairo",
          company_url: "https://www.linkedin.com/school/german-university-in-cairo/about/",
          logo_path: "guc.png",
          duration: "Jun 2012 - Sep 2012",
          location: "Cairo, Egypt",
          description:
            "Contributed to a team-based research project on automatic redirection of Antennas using a designed PCB prototype and CST software, resulting in a 3rd place award in the AP-S Student Design Challenge (USA, 2013).",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Extra-curricular Activities",
      experiences: [
        {
          title: "Food-truck",
          company: "Startup",
          logo_path: "google_logo.png",
          duration: "Mar 2019 - Sep 2019",
          location: "Cairo, Egypt",
          color: "#4285F4",
        },
        {
          title: "Judge",
          company: "IEEE Robotics and Automation Society - Minesweeper Competition",
          logo_path: "ieee.jpg",
          duration: "May 2013 - Jun 2013",
          location: "Cairo, Egypt",
          color: "#D83B01",
        },

      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Simulator-based Explanation and Debugging of Hazard-triggering Events in DNN-based Safety-critical Systems",
      createdAt: "Oct. '22",
      description: "ACM Transactions on Software Engineering and Methodolgy (TOSEM)",
      url:
        "https://dl.acm.org/doi/10.1145/3569935",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Black-box Safety Analysis and Retraining of DNNs based on Feature Extraction and Clustering",
      createdAt: "Jul. '22",
      description: "ACM Transactions on Software Engineering and Methodolgy (TOSEM)",
      url:
        "https://dl.acm.org/doi/10.1145/3550271",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "HUDD: A tool to debug DNNs for safety analysis",
      createdAt: "May. '22",
      description: "IEEE/ACM 44th International Conference on Software Engineering: Companion Proceedings (ICSE-Companion)",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Supporting Deep Neural Network Safety Analysis and Retraining Through Heatmap-Based Unsupervised Learning",
      createdAt: "May. '21",
      description: "IEEE Transactions on Reliability (TR)",
      url:
        "https://ieeexplore.ieee.org/abstract/document/9439863",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNbb=",
      name: "Vehicle Risk Assessment and Control for Lane-Keeping and Collision Avoidance in Urban and Highway Driving Scenarios",
      createdAt: "Feb. '18",
      description: "IEEE Transactions on Vehicular Technology (TVT) ",
      url:
        "https://ieeexplore.ieee.org/document/8295146",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNbb=",
      name: "V2V-based Vehicle Risk Assessment and Control for Lane-Keeping and Collision Avoidance",
      createdAt: "Dec. '17",
      description: "IEEE 29th International Conference on Microelectronics (ICM) ",
      url:
        "https://ieeexplore.ieee.org/abstract/document/8268891",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "hazem_logo.png",
  },
  blogSection: {
  },
  addressSection: {
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
